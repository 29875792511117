import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { fontStylesMap, device } from '../../styles/variables'
import { useFormatMessage } from '../i18n/hooks'
import { getMessageOrUndef } from '../i18n/utils'
import { ProfileName } from '../images/profile-image'
import Slider from '../slider/slider'
import Profile from './profile/profile'

const ourProfiles = [ProfileName.Kucera]
const profilesPartners = [ProfileName.Dejw, ProfileName.Kramolis]

const Container = styled.div`
  padding-top: 20px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  & > .heading {
    margin-top: 80px;
    ${fontStylesMap.headingOneLight}

    @media ${device.tablet} {
      margin-top: 40px;
        font-size: 34px;
      }

    &.secondary {
      ${fontStylesMap.textLinkBoldDark}
    }
  }

  & > .slider {
    margin-top: 40px;
  }
`

const WhoAreWe = () => {
  const t = useFormatMessage()

  return (
    <Container>
      <h2 className="heading">
        <FormattedMessage id="aboutUs.heading" />
      </h2>
      <Slider wrapperClassName="slider" showControls>
        {ourProfiles.map((profilePhoto, index) => {
          const prefix = `aboutUs.team.${index}`
          return (
            <Profile
              key={index}
              link={getMessageOrUndef(t, `${prefix}.link`)}
              name={t({ id: `${prefix}.name` })}
              title={t({ id: `${prefix}.title` })}
              text={t({ id: `${prefix}.text` })}
              photo={profilePhoto}
            />
          )
        })}
      </Slider>

      <h2 className="heading secondary">
        <FormattedMessage id="aboutUs.headingPartners" />
      </h2>
      <Slider wrapperClassName="slider" showControls>
        {profilesPartners.map((profilePhoto, index) => {
          const prefix = `aboutUs.partners.${index}`
          return (
            <Profile
              key={index}
              link={getMessageOrUndef(t, `${prefix}.link`)}
              name={t({ id: `${prefix}.name` })}
              title={t({ id: `${prefix}.title` })}
              text={t({ id: `${prefix}.text` })}
              photo={profilePhoto}
            />
          )
        })}
      </Slider>
    </Container>
  )
}

export default WhoAreWe
