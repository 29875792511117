import React from 'react'
import Button, { ButtonProps } from './button'
import { useFormatMessage } from '../i18n/hooks'

type Props = Omit<ButtonProps, 'title'>

const SendQuestionButton = (props: Props) => {
  const t = useFormatMessage()

  return (
    <Button
      className="button"
      title={t({ id: 'common.sendButton' })}
      icon={{ iconName: 'paper-plane', prefix: 'far' }}
      {...props}
    />
  )
}

export default SendQuestionButton
