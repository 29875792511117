import { useState, useEffect } from 'react'

const useIsScrolled = (pageOffset: number) => {
  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    const handleEvent = () => {
      if (window.pageYOffset > pageOffset) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    }

    window.addEventListener('scroll', handleEvent)
    handleEvent()

    return () => {
      window.removeEventListener('scroll', handleEvent)
    }
  }, [pageOffset])

  return isScrolled
}

export default useIsScrolled
