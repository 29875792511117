import React from 'react'
import styled from 'styled-components'
import PlusIcon from '../../../../assets/plus.svg'
import { colors, fontStylesMap, device } from '../../../styles/variables'
import { FAQType } from '../../../utils'

interface StyleProps {
  isOpen: boolean
  renderTopBorder: boolean
  renderBottomBorder: boolean
}

const Container = styled.div<StyleProps>`
  width: 650px;
  padding: 40px;

  background-color: ${({ isOpen }) => (isOpen ? colors.white : 'unset')};

  border-top: ${({ renderTopBorder }) => (renderTopBorder ? `1px solid ${colors.lightGray}` : 'unset')};
  border-bottom: ${({ renderBottomBorder }) => (renderBottomBorder ? `1px solid ${colors.lightGray}` : 'unset')};

  & > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .question {
      margin-right: 20px;

      ${fontStylesMap.question};
      color: ${({ isOpen }) => (isOpen ? colors.main : colors.dark)};
    }

    & .icon-wrapper {
      width: 30px;
      height: 30px;

      display: flex;
      justify-content: center;
      align-items: center;

      font-size: 30px;

      color: ${({ isOpen }) => (isOpen ? colors.gray : colors.main)};
      cursor: pointer;
      opacity: ${({ isOpen }) => (isOpen ? 0.3 : 1)};
      transform: ${({ isOpen }) => (isOpen ? 'rotate(45deg)' : 'unset')};

      & .icon {
        width: 25px;
        height: 25px;
      }
    }
  }

  & .answer {
    margin-top: 20px;
    ${fontStylesMap.textGray};
  }

  @media ${device.mobileL} {
    width: 90vw;

    & > .header {
      & .question {
        font-size: 16px;
      }
    }

    & .answer {
      font-size: 16px;
    }
  }
`

interface Props {
  faq: FAQType
  onClick: () => void
  openStates: boolean[]
  index: number
}

const Question = (props: Props) => {
  const { faq, onClick, openStates, index } = props
  const isOpen = openStates[index]

  // render top border only if closed and if first question
  const renderTopBorder = !isOpen && index === 0

  // render bottom border only is closed and next question is also closed
  const renderBottomBorder = !isOpen && !openStates[index + 1]

  return (
    <Container isOpen={isOpen} renderTopBorder={renderTopBorder} renderBottomBorder={renderBottomBorder}>
      <header className="header">
        <div className="question">{faq.question}</div>
        <div className="icon-wrapper" onClick={() => onClick()}>
          <div className="icon">
            <PlusIcon />
          </div>
        </div>
      </header>
      {isOpen ? <div className="answer">{faq.answer}</div> : null}
    </Container>
  )
}

export default Question
