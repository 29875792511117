import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import Circle from '../../../../assets/circle.svg'
import { fontStylesMap, device } from '../../../styles/variables'
import { FAQType, fetchFaq } from '../../../utils'
import { useFormatMessage } from '../../i18n/hooks'
import LocaleContext from '../../i18n/locale-context'
import Question from './question'

const SCircle = styled(Circle)`
  position: absolute;
  top: 0;
  left: 0;

  transform: translateX(-15%) translateY(35%);

  z-index: -1;
`

const Container = styled.div`
  margin-top: 300px;
  transition: .5s;

  @media ${device.tablet} {
    padding-top: 120px;
  }

  padding-bottom: 100px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  position: relative;
  z-index: 1;

  & .heading {
    ${fontStylesMap.headingOneLight}

    @media ${device.tablet} {
      font-size: 34px;
    }
  }

  & .questions {
    margin-top: 120px;
    @media ${device.tablet} {
      margin-top: 60px;
    }
  }
`

const handleClick = (state: boolean[], setState: React.Dispatch<React.SetStateAction<boolean[]>>, index: number) => {
  const currentState = state[index]

  const newState = [...state]
  newState.splice(index, 1, !currentState)
  setState(newState)
}

const FrequentlyAskedQuestions = () => {
  const t = useFormatMessage()
  const [faqs, setFaqs] = useState<FAQType[] | undefined>(undefined)
  const { locale: currentLocale } = useContext(LocaleContext)

  useEffect(() => {
    setFaqs(undefined) // undefined => isLoading
    const loadFaq = async () => {
      try {
        setFaqs(await fetchFaq()) // show fetched FAQs
      } catch (error) {
        setFaqs([]) // [] => no FAQ was found
      }
    }
    loadFaq()
  }, [currentLocale])

  const [openStates, setOpenStates] = useState<boolean[]>([])

  return (
    <Container>
      <SCircle />

      <h2 className="heading">{t({ id: 'faq.heading' })}</h2>

      <div className="questions">
        {!faqs && <div>{t({ id: 'faq.loadingFaqs' })}</div>}
        {faqs && faqs.length === 0 ? (
          <div>{t({ id: 'faq.noFaqs' })}</div>
        ) : (
          faqs?.map((faq, index) => (
            <Question
              key={faq.id}
              onClick={() => handleClick(openStates, setOpenStates, index)}
              {...{ index, faq, openStates }}
            />
          ))
        )}
      </div>
    </Container>
  )
}

export default FrequentlyAskedQuestions
