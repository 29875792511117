import cx from 'classnames'
import React, { Children, ReactNode, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import useScroll from '../../hooks/use-scroll'
import useWindowSize from '../../hooks/use-window-size'
import { calculateElementWidth, hasWindow } from '../../utils'
import { SliderControls } from './slider-controls'

interface StyledProps {
  childrenOffsetLeft: number
  childrenOffsetRight: number
}

const Container = styled.div<StyledProps>`
  overflow-x: auto;
  width: 100%;

  /* Firefox hide scrollbar */
  scrollbar-width: none;

  & > .slider-inner {
    display: flex;
    flex-direction: row;

    /* HACK for padding right to show up */
    /* More info here: https://stackoverflow.com/a/26892899 */
    float: left;

    padding-left: 50%;

    ${({ childrenOffsetLeft, childrenOffsetRight }) =>
      css`
        transform: translateX(-${childrenOffsetLeft}px);
        padding-right: ${childrenOffsetRight}px;
      `};
  }

  /* Webkit hide scrollbar */
  &::-webkit-scrollbar {
    display: none;
  }

  & * {
    user-select: none;
  }
`

interface Props {
  children?: ReactNode
  wrapperClassName?: string | undefined
  innerClassName?: string | undefined
  showControls?: boolean | undefined
}

type MouseEv = React.MouseEvent<HTMLDivElement, MouseEvent>

const Slider = (props: Props) => {
  const { children, wrapperClassName, innerClassName, showControls } = props

  const slider = useRef<HTMLDivElement>(null)
  const { x: sliderScrollX } = useScroll(slider)

  const { width: windowWidth } = useWindowSize()

  const [childrenWidth, setChildrenWidth] = useState(0)
  const [isMouseDown, setIsMouseDown] = useState(false)
  const [initialCursorPosition, setInitialCursorPosition] = useState(0)
  const [initialScrollLeft, setInitialScrollLeft] = useState(0)
  const [childrenOffsetLeft, setChildrenOffsetLeft] = useState(0)

  const getSliderRef = () => {
    const el = slider.current
    if (!el) {
      throw Error('Invalid ref!')
    }

    return el
  }

  useEffect(() => {
    // getting inner slider wrapper
    const innerDiv = getSliderRef().children[0]

    // getting "real" slider children
    const { children, firstElementChild } = innerDiv
    if (!firstElementChild || !hasWindow()) {
      return
    }

    const widthWithMargins = calculateElementWidth(firstElementChild, true)
    setChildrenWidth(widthWithMargins)

    // If there is only one child OR viewport is smaller than two children together, calculate the offset so it is centered
    if (children.length === 1 || windowWidth / childrenWidth < 2) {
      setChildrenOffsetLeft(childrenWidth / 2)
      return
    }

    setChildrenOffsetLeft(childrenWidth)
  }, [childrenOffsetLeft, childrenWidth, windowWidth])

  const setScrollPosition = (position: number) => {
    getSliderRef().scrollLeft = position
  }

  const setScrollPositionByIndex = (index: number) => setScrollPosition(index * childrenWidth)

  const getCursorPosition = (pageX: number) => {
    const { offsetLeft } = getSliderRef()
    return pageX - offsetLeft
  }

  const onMouseDown = (e: MouseEv) => {
    setIsMouseDown(true)

    const { pageX } = e
    const { scrollLeft } = getSliderRef()

    const cursorPosition = getCursorPosition(pageX)
    setInitialCursorPosition(cursorPosition)
    setInitialScrollLeft(scrollLeft)
  }

  const onMouseMove = (e: MouseEv) => {
    if (!isMouseDown) {
      return
    }

    e.preventDefault()

    const { pageX } = e
    const cursorPosition = getCursorPosition(pageX)
    const cursorMovement = cursorPosition - initialCursorPosition
    setScrollPosition(initialScrollLeft - cursorMovement)
  }

  return (
    <>
      <Container
        ref={slider}
        className={wrapperClassName}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={() => setIsMouseDown(false)}
        onMouseLeave={() => setIsMouseDown(false)}
        childrenOffsetLeft={childrenOffsetLeft}
        childrenOffsetRight={(windowWidth - childrenWidth * Math.floor(windowWidth / childrenWidth)) / 2}
      >
        <div className={cx('slider-inner', innerClassName)} draggable={false}>
          {children}
        </div>
      </Container>

      {showControls && (
        <SliderControls
          onSelect={childIndex => setScrollPositionByIndex(childIndex)}
          numberOfChildren={Children.count(children)}
          sliderScrollPosition={sliderScrollX}
          childrenWidth={childrenWidth}
        />
      )}
    </>
  )
}

export default Slider
