import React from 'react'
import { MessageDescriptor } from 'react-intl'
import { ReactNode } from 'react'
import { useFormatMessage } from '../i18n/hooks'

export enum LinkTarget {
  blank = '_blank',
  self = '_self',
  parent = '_parent',
  top = '_top',
}

interface Props {
  titleDescriptor: MessageDescriptor
  children: ReactNode
  href: string
  className?: string
  target?: LinkTarget
}

const CustomLink = ({ titleDescriptor, children, target = LinkTarget.blank, ...otherProps }: Props) => {
  const t = useFormatMessage()

  return (
    <a
      title={t(titleDescriptor)}
      target={target}
      rel={target === LinkTarget.blank ? 'noopener noreferrer' : undefined}
      {...otherProps}
    >
      {children}
    </a>
  )
}

export default CustomLink
