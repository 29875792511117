import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

export enum ProfileName {
  Kucera = 'kucera',
  Kramolis = 'kramolis',
  Dejw = 'dejw',
}

const query = graphql`
  {
    kucera: file(relativePath: { eq: "profiles/kucera.png" }) {
      childImageSharp {
        fixed(width: 120) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    kramolis: file(relativePath: { eq: "profiles/kramolis.png" }) {
      childImageSharp {
        fixed(width: 120) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    dejw: file(relativePath: { eq: "profiles/dejw.png" }) {
      childImageSharp {
        fixed(width: 120) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
  }
`

interface Props {
  className?: string | undefined
  name: ProfileName
}

const ProfileImage = (props: Props) => {
  const { name } = props
  const data = useStaticQuery(query)

  return <Img fixed={data[name].childImageSharp.fixed} {...props} />
}

export default ProfileImage
