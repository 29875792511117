import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const query = graphql`
  {
    arrow: file(relativePath: { eq: "arrow.png" }) {
      childImageSharp {
        fixed(width: 50) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
  }
`

interface Props {
  className?: string | undefined
}

const Arrow = (props: Props) => {
  const { arrow } = useStaticQuery(query)
  return <Img fixed={arrow.childImageSharp.fixed} {...props} />
}

export default Arrow
