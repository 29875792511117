import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import CustomLink from './customLink'
import { MarketType } from '../../utils'

const query = graphql`
  {
    googleplay: file(relativePath: { eq: "links/badge-google-play-beta.png" }) {
      childImageSharp {
        fixed(width: 210) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    appstore: file(relativePath: { eq: "links/badge-app-store-beta.png" }) {
      childImageSharp {
        fixed(width: 210) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
  }
`

const Container = styled.div`
  margin: 1em;
  .withoutMargin {
    margin 0;
  }
`

const marketLinks = {
  [MarketType.appStore]: 'https://testflight.apple.com/join/t3OqZpeN',
  [MarketType.googlePlay]: 'https://play.google.com/store/apps/details?id=eu.datari',
}

interface Props {
  market: MarketType
  withoutMargin?: boolean
}

const MarketLink = ({ market, withoutMargin }: Props) => {
  const images = useStaticQuery(query)
  return (
    <Container>
      <CustomLink
        className={withoutMargin ? 'withoutMargin' : undefined}
        href={marketLinks[market]}
        titleDescriptor={{ id: `common.${market}LinkTitle` }}
      >
        <Img fixed={images[market].childImageSharp.fixed} />
      </CustomLink>
    </Container>
  )
}

export default MarketLink
