import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const query = graphql`
  {
    alza: file(relativePath: { eq: "logos/alza.png" }) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    amazon: file(relativePath: { eq: "logos/amazon.png" }) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    billa: file(relativePath: { eq: "logos/billa.png" }) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    facebook: file(relativePath: { eq: "logos/facebook.png" }) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    gmail: file(relativePath: { eq: "logos/gmail.png" }) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    google: file(relativePath: { eq: "logos/google.png" }) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    instagram: file(relativePath: { eq: "logos/instagram.png" }) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    komercni_banka: file(relativePath: { eq: "logos/komercni-banka.png" }) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    mall: file(relativePath: { eq: "logos/mall.png" }) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    messenger: file(relativePath: { eq: "logos/messenger.png" }) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    raiffeisen_bank: file(relativePath: { eq: "logos/raiffeisen-bank.png" }) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    seznam: file(relativePath: { eq: "logos/seznam.png" }) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    tesco: file(relativePath: { eq: "logos/tesco.png" }) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    twitter: file(relativePath: { eq: "logos/twitter.png" }) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    whatsapp: file(relativePath: { eq: "logos/whatsapp.png" }) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
  }
`

export enum Company {
  Alza = 'alza',
  Amazon = 'amazon',
  Billa = 'billa',
  Facebook = 'facebook',
  Gmail = 'gmail',
  Google = 'google',
  Instagram = 'instagram',
  KomercniBanka = 'komercni_banka',
  Mall = 'mall',
  Messenger = 'messenger',
  RaiffeisenBank = 'raiffeisen_bank',
  Seznam = 'seznam',
  Tesco = 'tesco',
  Twitter = 'twitter',
  Whatsapp = 'whatsapp',
}

interface Props {
  className?: string | undefined
  company: Company
}

const CompanyLogo = (props: Props) => {
  const { company } = props
  const data = useStaticQuery(query)

  return <Img fixed={data[company].childImageSharp.fixed} {...props} />
}

export default CompanyLogo
