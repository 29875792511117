import React from 'react'
import styled from 'styled-components'
import SendQuestionButton from './buttons/send-question-button'
import { useFormatMessage } from './i18n/hooks'
import { device } from '../styles/variables'
import { MessageDescriptor } from 'react-intl'

const Container = styled.div`
  position: relative;

  & .input {
    width: 620px;
    height: 48px;
    padding-right: 250px;
    padding-left: 20px;

    border: none;
    border-radius: 24px;
    outline: none;

    font-family: inherit;
  }

  & .button {
    position: absolute;
    right: 0;
    top: 0;
  }

  @media ${device.mobileL} {
    & .input {
      width: 250px;

      padding-right: 20px;
      padding-left: 20px;
      margin-bottom: 20px;

      font-size: 14px;

      &::placeholder {
        text-align: center;
      }
    }

    & .button {
      width: 250px;

      position: static;

      justify-content: center;

      font-size: 14px;
    }
  }
`

interface Props {
  name: string
  value: string
  onChange: (newValue: string) => void
  isSubmitting?: boolean
  placeholderDescriotion?: MessageDescriptor
  required?: boolean
}

const EmailInput = (props: Props) => {
  const { name, value, onChange, isSubmitting, placeholderDescriotion, required } = props

  const t = useFormatMessage()

  return (
    <Container>
      <input
        type="email"
        className="input"
        onChange={e => onChange(e.target.value)}
        placeholder={placeholderDescriotion && t(placeholderDescriotion)}
        {...{ name, value, required }}
      />

      <SendQuestionButton type="submit" isLoading={isSubmitting} isDisabled={isSubmitting} />
    </Container>
  )
}

export default EmailInput
