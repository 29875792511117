import React from 'react'
import styled from 'styled-components'
import { colors, fontStylesMap, device } from '../../../styles/variables'
import ProfileImage, { ProfileName } from '../../images/profile-image'
import PlusLink from '../../plus-link'

const Container = styled.div`
  margin-left: 26px;
  margin-right: 26px;
  margin-top: 80px;
  margin-bottom: 80px;

  & > .body {
    position: relative;

    width: 496px;
    height: 460px;
    padding: 40px;
    padding-top: 95px;

    border-radius: 6px;
    background-color: ${colors.white};

    filter: drop-shadow(0 5px 15px rgba(0, 0, 0, 0.07));

    & > .name {
      display: flex;
      justify-content: center;
      align-items: center;

      ${fontStylesMap.cardHeading};
    }

    & > .title {
      display: flex;
      justify-content: center;
      align-items: center;

      margin-top: 10px;

      ${fontStylesMap.textLinkBoldColored};
      text-align: center;
      line-height: 1.4;
    }

    & > .text {
      display: flex;
      justify-content: center;
      align-items: center;

      margin-top: 40px;

      ${fontStylesMap.profileTextItalic};
      text-align: center;
    }

    & > .photo-wrapper {
      position: absolute;
      top: 0;
      left: 50%;

      transform: translateY(-50%) translateX(-50%);

      & > .photo {
        border-radius: 50%;
      }
    }

    & .triangle {
      position: absolute;

      bottom: -30px;
      left: 30px;

      /* Triangle magic */
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 40px 40px 0 0;
      border-color: #ffffff transparent transparent transparent;
      /* End of triangle magic */
    }
  }

  & > .link {
    justify-content: center;
  }

  @media ${device.mobileL} {
    & > .body {
      width: 380px;
      height: 380px;

      & > .name {
        font-size: 23px;
      }

      & > .text {
        font-size: 14px;
      }
    }
  }

  @media ${device.mobileM} {
    & > .body {
      width: 300px;
      height: 400px;

      & > .name {
        font-size: 21px;
      }

      & > .text {
        font-size: 12px;
      }
    }
  }
`

interface Props {
  photo: ProfileName
  name: string
  title: string
  text: string
  link?: string
}

const Profile = (props: Props) => {
  const { name, title, text, link, photo } = props

  return (
    <Container>
      <div className="body">
        <div className="triangle" />

        <div className="photo-wrapper">
          <ProfileImage name={photo} className="photo" />
        </div>

        <h5 className="name">{name}</h5>

        <h6 className="title">{title}</h6>

        <p className="text">{text}</p>
      </div>

      {link && <PlusLink className="link" title={link} />}
    </Container>
  )
}

export default Profile
