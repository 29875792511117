import React, { useState } from 'react'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import { useStaticQuery, graphql } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import EmailInput from '../../email-input'
import CustomTextarea from '../../custom-textarea'
import { fontStylesMap, fontWeight, colors, device } from '../../../styles/variables'
import { fetchPost } from '../../../utils'
import { getLocale } from '../../i18n/utils'

const query = graphql`
  query {
    desktop: file(relativePath: { eq: "footer.png" }) {
      childImageSharp {
        fluid(maxWidth: 4320, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Container = styled(BackgroundImage)`
  margin-left: 100px;
  margin-right: 100px;

  min-height: 90vh;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  border-radius: 10px;
  overflow: hidden;

  & .heading {
    margin-left: 20px;
    margin-right: 20px;

    ${fontStylesMap.headingOneExtraLight};
    text-align: center;

    & .colored {
      font-weight: ${fontWeight.bold};
      color: ${colors.main};
    }

    @media ${device.laptop} {
      font-size: 34px;
    }

    @media ${device.mobileL} {
      font-size: 22px;
      line-height: 1.6;
    }
  }

  & .form {
    margin-top: 80px;

    & .link {
      ${fontStylesMap.textLinkSmall};
      cursor: pointer;
    }

    @media ${device.tablet} {
      margin-top: 40px;
    }
  }

  & .error-message {
    margin-top: 30px;
    ${fontStylesMap.headingTwoWhite};
    text-align: center;
    font-size: 28px;

    @media ${device.laptop} {
      font-size: 20px;
    }

    @media ${device.mobileL} {
      font-size: 16px;
      line-height: 1.6;
    }
  }
  @media ${device.tablet} {
    min-height: unset;
    padding-top: 100px;
    padding-bottom: 100px;

    margin-left: 0px;
    margin-right: 0px;

    border-radius: 0px;
  }
`

export enum QuestionFormState {
  initial = 'initial',
  submiting = 'submiting',
  success = 'success',
  error = 'error',
}

const QuestionForm = () => {
  const { desktop } = useStaticQuery(query)
  // const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  // const [withError, setWithError] = useState<boolean>(false)
  // const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [formState, setFormState] = useState<QuestionFormState>(QuestionFormState.initial)
  const [email, setEmail] = useState<string>('')
  const [question, setQuestion] = useState<string>('')

  const language = getLocale()
  const isSubmitted = formState === QuestionFormState.success
  const isError = formState === QuestionFormState.error
  const isSubmitting = formState === QuestionFormState.submiting

  return (
    <Container
      fluid={desktop.childImageSharp.fluid}
      onSubmit={async e => {
        e.preventDefault()

        if (question === '') {
          // TODO: custom validation
          return
        }

        setFormState(QuestionFormState.submiting)

        try {
          const response = await fetchPost(`/api/web/support`, {
            subject: 'Feedback form/web',
            body: `${question}\n\nprovided email: ${email || 'none'}, language: ${language}`,
          })
          if (!response.ok) {
            throw Error(response.statusText)
          }
          setFormState(QuestionFormState.success)
          setEmail('')
          setQuestion('')
        } catch (err) {
          setFormState(QuestionFormState.error)
        }
      }}
    >
      <h2 className="heading">
        <FormattedMessage id={isSubmitted ? 'footer.headingSuccess.text' : 'footer.heading.text'} />{' '}
        <span className="colored">
          <FormattedMessage id={isSubmitted ? 'footer.headingSuccess.textBold' : 'footer.heading.textBold'} />
        </span>
      </h2>

      <div className="form">
        {!isSubmitted ? (
          <form>
            <CustomTextarea
              name="question"
              value={question}
              onChange={setQuestion}
              isSubmitting={isSubmitting}
              placeholderDescriotion={{ id: 'footer.questionPlaceholder' }}
              rows={10}
              required
            />
            <EmailInput
              name="email"
              value={email}
              onChange={setEmail}
              isSubmitting={isSubmitting}
              placeholderDescriotion={{ id: 'common.emailPlaceholder' }}
            />
          </form>
        ) : (
          <div className="link" onClick={() => setFormState(QuestionFormState.initial)}>
            <FormattedMessage id={'footer.sendAnotherQuestion'} />
          </div>
        )}
      </div>
      {isError && (
        <h3 className="error-message">
          <FormattedMessage id={'footer.errorMessage'} />
        </h3>
      )}
    </Container>
  )
}

export default QuestionForm
