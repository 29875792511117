import React from 'react'
import styled from 'styled-components'
import { fontStylesMap } from '../styles/variables'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

const Container = styled.div`
  display: inline-block;

  & .wrapper {
    padding-left: 22px;
    padding-right: 22px;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    cursor: pointer;
    user-select: none;

    ${fontStylesMap.textLink}
  }

  & .icon {
    margin-right: 10px;
  }
`

interface Props {
  title: string
  className?: string
  onClick?: () => void
  icon?: undefined | IconProp
}

const TextLink = (props: Props) => {
  const { title, className, icon } = props

  return (
    <Container className={className}>
      <div className="wrapper">
        {icon ? (
          <div className="icon">
            <FontAwesomeIcon icon={icon} />
          </div>
        ) : null}
        <div>{title}</div>
      </div>
    </Container>
  )
}

export default TextLink
