import React from 'react'
import useIsScrolled from '../../hooks/use-is-scrolled'
import useMediaQuery from '../../hooks/use-media-query'
import NavigationDesktop from './navigation-desktop'
import NavigationMobile from './navigation-mobile'
import { NavigationLink, NavigationLinkWithTranslation } from './utils'

const links: NavigationLinkWithTranslation[] = [
  {
    href: '#how-does-it-work',
    translationId: 'common.navigationLinks.howDoesItWork',
  },
  {
    href: '#who-are-we',
    translationId: 'common.navigationLinks.whoAreWe',
  },
  {
    href: '#faq',
    translationId: 'common.navigationLinks.faq',
  },
  {
    href: '#contact',
    translationId: 'common.navigationLinks.contact',
  },
]

const logoLink: NavigationLink = {
  href: '#home',
}

const buttonLink: NavigationLink = {
  href: '#contact',
}

const Navigation = () => {
  const isMobile = useMediaQuery('(max-width: 1100px)')
  const isScrolled = useIsScrolled(50)

  const props = { isScrolled, links, logoLink, buttonLink }

  return isMobile ? <NavigationMobile {...props} /> : <NavigationDesktop {...props} />
}

export default Navigation
