import React, { ChangeEvent } from 'react'
import styled from 'styled-components'

const Container = styled.label`
  .react-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
    display: none;
  }

  .react-switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 50px;
    height: 25px;
    background: grey;
    border-radius: 50px;
    position: relative;
    transition: background-color 0.2s;
  }
  .react-switch-label .react-switch-button {
    content: '';
    position: absolute;
    top: 1.5px;
    left: 1px;
    width: 22.5px;
    height: 22.5px;
    border-radius: 22.5px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 1px 0 rgba(10, 10, 10, 0.29);
  }

  .react-switch-checkbox:checked + .react-switch-label .react-switch-button {
    left: calc(100% - 1px);
    transform: translateX(-100%);
  }

  .react-switch-label:active .react-switch-button {
    width: 30px;
  }
`

interface Props {
  isOn: boolean
  handleToggle?: (event: ChangeEvent<HTMLInputElement>) => void
  onColor?: string
}

const SwitchButton = ({ isOn, handleToggle, onColor = '#06d6a0' }: Props) => {
  return (
    <Container>
      <input
        checked={isOn}
        onChange={handleToggle}
        className="react-switch-checkbox"
        id={`react-switch-new`}
        type="checkbox"
      />
      <label
        className="react-switch-label"
        htmlFor={`react-switch-new`}
        style={{ background: isOn ? onColor : undefined }}
      >
        <span className={`react-switch-button`} />
      </label>
    </Container>
  )
}

export default SwitchButton
