import { useEffect } from 'react'
import useRafState from './use-raf-state'
import { hasWindow } from '../utils'

const useWindowSize = (initialWidth = Infinity, initialHeight = Infinity) => {
  const [state, setState] = useRafState<{ width: number; height: number }>({
    width: hasWindow() ? window.innerWidth : initialWidth,
    height: hasWindow() ? window.innerHeight : initialHeight,
  })

  useEffect((): (() => void) | void => {
    if (hasWindow()) {
      const handler = () => {
        setState({
          width: window.innerWidth,
          height: window.innerHeight,
        })
      }

      window.addEventListener('resize', handler)

      return () => {
        window.removeEventListener('resize', handler)
      }
    }
  }, [setState])

  return state
}

export default useWindowSize
