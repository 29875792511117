export interface NavigationLink {
  href: string
}

export interface NavigationLinkWithTranslation extends NavigationLink {
  translationId: string
}

export interface NavigationProps {
  links: NavigationLinkWithTranslation[]
  logoLink: NavigationLink
  buttonLink: NavigationLink
  isScrolled: boolean
}

export const HEADER_HEIGHT = 48
