import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { Transition } from 'react-transition-group'
import { TransitionStatus, ENTERING, ENTERED, EXITING, EXITED } from 'react-transition-group/Transition'
import styled, { css } from 'styled-components'
import { colors, fontStylesMap } from '../../styles/variables'
import SmoothScrollLink from '../smooth-scroll-link'
import { HEADER_HEIGHT, NavigationLink, NavigationLinkWithTranslation } from './utils'
import { getMarketType } from '../../utils'
import MarketLink from '../links/market-link'

const ANIMATION_TIMEOUT = 300

interface StyledProps {
  state: TransitionStatus
}

const Container = styled.div<StyledProps>`
  position: fixed;
  top: ${HEADER_HEIGHT}px;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${colors.white};

  & > .links {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & > * {
      margin-bottom: 30px;
      ${fontStylesMap.links};
      font-size: 18px;
    }
  }

  transition: opacity ${ANIMATION_TIMEOUT}ms ease-in-out;
  opacity: 0;

  ${({ state }) => {
    switch (state) {
      case ENTERING:
        return css`
          opacity: 1;
        `
      case ENTERED:
        return css`
          opacity: 1;
        `
      case EXITING:
        return css`
          opacity: 0;
        `
      case EXITED:
        return css`
          opacity: 0;
        `
    }
  }};
`

interface Props {
  isOpen: boolean
  links: NavigationLinkWithTranslation[]
  buttonLink: NavigationLink
  onLinkClick: () => void
}

export const NavigationMobileBody: FC<Props> = props => {
  const { isOpen, onLinkClick, links } = props
  const marketType = getMarketType()

  return (
    // problem with enter animation
    // https://github.com/reactjs/react-transition-group/issues/366
    <Transition in={isOpen} timeout={ANIMATION_TIMEOUT} mountOnEnter unmountOnExit>
      {state => (
        <Container state={state}>
          <div className="links">
            {links.map((link, i) => (
              <SmoothScrollLink href={link.href} key={i} onClick={onLinkClick}>
                <FormattedMessage id={link.translationId} />
              </SmoothScrollLink>
            ))}
            {marketType && <MarketLink market={marketType} withoutMargin />}
          </div>
        </Container>
      )}
    </Transition>
  )
}
