import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { fontStylesMap } from '../../../styles/variables'
import Divider from '../../divider'
import CompanyLogo, { Company } from '../../images/company-logo'
import useMediaQuery from '../../../hooks/use-media-query'

const Container = styled.div`
  margin-top: 60px;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;

  & .column {
    width: 180px;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 30px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: column;

    & .column-header {
      padding-left: 10px;
      padding-right: 10px;

      ${fontStylesMap.textLinkBoldDark};
      text-align: center;
    }

    & .logo-wrapper {
      height: 32px;
      margin-top: 50px;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`

const Companies = () => {
  const showDivider = !useMediaQuery('(max-width: 1200px)')

  return (
    <Container>
      {showDivider && <Divider />}

      <div className="column">
        <h4 className="column-header">
          <FormattedMessage id="companies.categories.communications" />
        </h4>
        <div className="logo-wrapper">
          <CompanyLogo company={Company.Gmail} />
        </div>
        <div className="logo-wrapper">
          <CompanyLogo company={Company.Seznam} />
        </div>
        <div className="logo-wrapper">
          <CompanyLogo company={Company.Whatsapp} />
        </div>
        <div className="logo-wrapper">
          <CompanyLogo company={Company.Messenger} />
        </div>
      </div>

      {showDivider && <Divider />}

      <div className="column">
        <h4 className="column-header">
          <FormattedMessage id="companies.categories.social" />
        </h4>
        <div className="logo-wrapper">
          <CompanyLogo company={Company.Facebook} />
        </div>
        <div className="logo-wrapper">
          <CompanyLogo company={Company.Twitter} />
        </div>
        <div className="logo-wrapper">
          <CompanyLogo company={Company.Instagram} />
        </div>
      </div>

      {showDivider && <Divider />}

      <div className="column">
        <h4 className="column-header">
          <FormattedMessage id="companies.categories.search" />
        </h4>
        <div className="logo-wrapper">
          <CompanyLogo company={Company.Google} />
        </div>
        <div className="logo-wrapper">
          <CompanyLogo company={Company.Seznam} />
        </div>
      </div>

      {showDivider && <Divider />}

      <div className="column">
        <h4 className="column-header">
          <FormattedMessage id="companies.categories.stores" />
        </h4>
        <div className="logo-wrapper">
          <CompanyLogo company={Company.Amazon} />
        </div>
        <div className="logo-wrapper">
          <CompanyLogo company={Company.Alza} />
        </div>
        <div className="logo-wrapper">
          <CompanyLogo company={Company.Mall} />
        </div>
        <div className="logo-wrapper">
          <CompanyLogo company={Company.Tesco} />
        </div>
        <div className="logo-wrapper">
          <CompanyLogo company={Company.Billa} />
        </div>
      </div>

      {showDivider && <Divider />}

      <div className="column">
        <h4 className="column-header">
          <FormattedMessage id="companies.categories.finance" />
        </h4>
        <div className="logo-wrapper">
          <CompanyLogo company={Company.KomercniBanka} />
        </div>
        <div className="logo-wrapper">
          <CompanyLogo company={Company.RaiffeisenBank} />
        </div>
      </div>

      {showDivider && <Divider />}
    </Container>
  )
}

export default Companies
