import React, { useState } from 'react'
import styled from 'styled-components'
import { fontStylesMap, colors } from '../styles/variables'
import PlusIcon from './plus-icon'

const Container = styled.a`
  margin-top: 30px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  cursor: pointer;

  & .link-icon {
    margin-right: 10px;
  }

  & .link-title {
    position: relative;
    ${fontStylesMap.textLinkBoldColored}

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: -2px;
      left: 0;

      background-color: ${colors.main};

      transform: scaleX(0);
      transform-origin: right center;
      transition: all 0.3s ease-in-out 0s;
    }
  }

  &:hover {
    & .link-title:before {
      transform: scaleX(1);
    }
  }
`

interface Props {
  title: string
  url?: string | undefined
  className?: string | undefined
}

const PlusLink = (props: Props) => {
  const { title, className, url } = props
  const [isHovered, setIsHovered] = useState(false)

  return (
    <Container
      href={url}
      className={className}
      onClick={e => !url && e.preventDefault()}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <PlusIcon className="link-icon" isHovered={isHovered} />
      <div className="link-title">{title}</div>
    </Container>
  )
}

export default PlusLink
