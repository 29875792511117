import React, { useContext, useState } from 'react'
import styled, { css } from 'styled-components'
import LocaleContext from './locale-context'
import { colors } from '../../styles/variables'
import ArrowMenuImage from '../images/arrow-menu'

interface StyledProps {
  isOpen: boolean
}

const Container = styled.div<StyledProps>`
  position: relative;

  width: 50px;
  margin-right: 10px;

  border-radius: 6px;

  ${({ isOpen }) =>
    isOpen &&
    css`
      border: 1px solid ${colors.lightGray};
      background-color: ${colors.white};
      border-bottom: none;
      border-radius: 6px 6px 0px 0px;
    `};

  & .option {
    padding-left: 10px;
    height: 30px;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    user-select: none;
    cursor: pointer;
    ${({ isOpen }) =>
      isOpen &&
      css`
        &: hover {
          background-color: ${colors.lightGray};
        }
      `}
  }

  & .option-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  & .dropdown-img {
    margin-left: 5px;
    display: flex;
    align-items: center;
  }

  & > .dropdown {
    position: absolute;
    left: -1px;
    right: -1px;

    max-height: 200px;
    overflow-y: auto;

    border-radius: 0px 0px 6px 6px;

    ${({ isOpen }) =>
      isOpen &&
      css`
        border: 1px solid ${colors.lightGray};
        border-top: none;
        background-color: ${colors.white};
      `}
  }
`

const LanguageSwitch = () => {
  const [isOpen, setIsOpen] = useState(false)

  const { locale: currentLocale, setLocale } = useContext(LocaleContext)

  // HACK to only show cs and en locales
  // Otherwise locales are took from available crowdin transaltions
  // const locales = getLocales().filter(l => l !== currentLocale)
  const locales = ['cs', 'en'].filter(l => l !== currentLocale)

  return (
    <Container isOpen={isOpen}>
      <div className="option" onClick={() => setIsOpen(!isOpen)}>
        <div className="option-row">
          <div>{currentLocale.toUpperCase()}</div>
          {!isOpen && (
            <span className="dropdown-img">
              <ArrowMenuImage />
            </span>
          )}
        </div>
      </div>
      {isOpen && (
        <div className="dropdown">
          {locales.map(loc => (
            <div
              className="option"
              key={loc}
              onClick={() => {
                setIsOpen(false)
                setLocale && setLocale(loc)
              }}
            >
              <div className="option-row">
                <div>{loc.toUpperCase()}</div>
              </div>
            </div>
          ))}
        </div>
      )}
    </Container>
  )
}

export default LanguageSwitch
