import React from 'react'
import Behavior from '../../../../assets/data/behavior.svg'
import Grocery from '../../../../assets/data/grocery.svg'
import Health from '../../../../assets/data/health.svg'
import Locations from '../../../../assets/data/locations.svg'
import Media from '../../../../assets/data/media.svg'
import Transactions from '../../../../assets/data/transactions.svg'

export enum DataIconName {
  Behavior = 'behavior',
  Grocery = 'grocery',
  Locations = 'locations',
  Health = 'health',
  Media = 'media',
  Transactions = 'transactions',
}

interface Props {
  name: DataIconName
}

const DataIcon = (props: Props) => {
  const iconProps = { width: 100, height: 100 }

  switch (props.name) {
    case DataIconName.Behavior:
      return <Behavior {...iconProps} />
    case DataIconName.Grocery:
      return <Grocery {...iconProps} />
    case DataIconName.Locations:
      return <Locations {...iconProps} />
    case DataIconName.Health:
      return <Health {...iconProps} />
    case DataIconName.Media:
      return <Media {...iconProps} />
    case DataIconName.Transactions:
      return <Transactions {...iconProps} />
    default:
      return null // TODO
  }
}

export default DataIcon
