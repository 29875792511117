import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { colors, device, fontStylesMap } from '../../../styles/variables'
import QuestionForm from './question-form'
import { useFormatMessage } from '../../i18n/hooks'
import CustomLink from '../../links/customLink'
import { getLocale } from '../../i18n/utils'
import { getDoNotTrack, setDoNotTrack } from '../../auth/utils'
import SwitchButton from '../../buttons/switch'

const Container = styled.div`
  margin-top: 200px;
  padding-bottom: 100px;

  position: relative;
  z-index: 1;

  & .contact-us {
    margin-top: 30px;
    ${fontStylesMap.textGray};
    color: ${colors.dark};

    text-align: center;

    & .email {
      color: ${colors.main};
      text-decoration: underline;
    }
  }

  & > .copyright {
    margin-top: 100px;
    margin-left: 30px;
    margin-right: 30px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & .line-one {
      margin: 20px 0;
      display: flex;
      align-items: center;

      .switch {
        margin-right: 10px;
      }
      .text {
        cursor: pointer;
      }
    }

    & .line-two {
      margin-top: 10px;

      ${fontStylesMap.copyrightText}

      & > .link:hover {
        text-decoration: underline;
      }
    }
  }

  @media ${device.mobileL} {
    padding-top: 50px;
  }
`

const Footer = () => {
  const [isClient, setIsSSR] = useState(false)
  useEffect(() => setIsSSR(true), [])

  const t = useFormatMessage()
  const email = t({ id: 'footer.email' })
  const locale = getLocale()
  const [isTrackingLocal, setIsTrackingLocal] = useState<boolean>(!getDoNotTrack())

  const toggleDoNotTrack = () => {
    setDoNotTrack(isTrackingLocal)
    setIsTrackingLocal(!isTrackingLocal)
  }

  return (
    <Container>
      <QuestionForm />

      <p className="contact-us">
        <FormattedMessage id="footer.callToAction" />{' '}
        <a href={`mailto:${email}`} className="email">
          <FormattedMessage id={email} />.
        </a>
      </p>

      <div className="copyright">
        <div className="line-one">
          <span className="switch">
            {isClient && <SwitchButton isOn={!getDoNotTrack()} handleToggle={toggleDoNotTrack} />}
          </span>
          <span className="text" onClick={toggleDoNotTrack}>
            <FormattedMessage id={isTrackingLocal ? 'footer.doNotTrack' : 'footer.doTrack'} />{' '}
          </span>
        </div>
        <p className="line-two">
          {isClient && (
            <CustomLink
              className="link"
              titleDescriptor={{ id: 'footer.privacyPolicyTitle' }}
              href={`/docs/privacy_${locale}.pdf`}
            >
              {t({ id: 'footer.privacyPolicyText' })}
            </CustomLink>
          )}{' '}
          | Copyright {new Date().getFullYear()} Datari
        </p>
      </div>
    </Container>
  )
}

export default Footer
