import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

interface Props {
  title: string
  icon?: undefined | IconProp
}

const ButtonContent = (props: Props) => {
  const { icon, title } = props

  return (
    <>
      {icon ? (
        <div className="icon">
          <FontAwesomeIcon icon={icon} />
        </div>
      ) : null}
      <div>{title}</div>
    </>
  )
}

export default ButtonContent
