import React, { Fragment } from 'react'
import styled from 'styled-components'
import useMediaQuery from '../../../hooks/use-media-query'
import Divider from '../../divider'
import { useFormatMessage } from '../../i18n/hooks'
import DataBlock from './data-block'
import { DataIconName } from './data-icon'

const icons = [
  DataIconName.Media,
  DataIconName.Locations,
  DataIconName.Transactions,
  DataIconName.Grocery,
  DataIconName.Behavior,
  DataIconName.Health,
]

const Container = styled.div`
  margin-top: 60px;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
`

const Data = () => {
  const t = useFormatMessage()
  const showDivider = !useMediaQuery('(max-width: 1200px)')

  return (
    <Container>
      {showDivider && <Divider />}
      {icons.map((icon, index) => {
        const prefix = `data.icons.${index}`

        return (
          <Fragment key={index}>
            <DataBlock text={t({ id: `${prefix}.title` })} icon={icon} />
            {showDivider && <Divider />}
          </Fragment>
        )
      })}
    </Container>
  )
}

export default Data
