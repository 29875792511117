import React, { FC, AnchorHTMLAttributes } from 'react'

type ClickEvent = React.MouseEvent<HTMLAnchorElement, MouseEvent>

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
  offset?: number | undefined
}

const pushHashToUrl = (hash: string) => {
  if (history.replaceState) {
    history.replaceState(null, '', hash)
    return
  }

  location.hash = hash
}

const SmoothScrollLink: FC<Props> = props => {
  const { offset = 0, onClick, ...rest } = props

  const handleClick = (e: ClickEvent) => {
    const href = e.currentTarget.getAttribute('href')
    // If href does not point for element on page, link should work as normal
    if (!href || !href.startsWith('#')) {
      return
    }

    const targetElementId = href.replace('#', '')
    const targetElement = document.getElementById(targetElementId)
    if (!targetElement) {
      return
    }

    e.preventDefault()
    const offsetTop = targetElement.getBoundingClientRect().top + window.pageYOffset

    window.scroll({ top: offsetTop - offset, behavior: 'smooth' })
    pushHashToUrl(href)
    onClick && onClick(e)
  }

  return <a {...rest} onClick={handleClick} />
}

export default SmoothScrollLink
