import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import Arrow from './images/arrow'
import useWindowScroll from '../hooks/use-window-scroll'
import useClickAway from '../hooks/use-click-away'
import { Transition } from 'react-transition-group'
import { TransitionStatus, EXITED, ENTERED, ENTERING, EXITING } from 'react-transition-group/Transition'
import AnchorLink from './smooth-scroll-link'

interface StyledProps {
  state: TransitionStatus
}

const ANIMATION_TIMEOUT = 300

const Container = styled.div<StyledProps>`
  height: 200px;

  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 80%, rgba(255, 255, 255, 1) 100%);

  z-index: 100;

  transition: opacity ${ANIMATION_TIMEOUT}ms ease-in-out;
  opacity: 0;

  ${({ state }) => {
    switch (state) {
      case ENTERING:
        return css`
          opacity: 1;
        `
      case ENTERED:
        return css`
          opacity: 1;
        `
      case EXITING:
        return css`
          opacity: 0;
        `
      case EXITED:
        return css`
          opacity: 0;
        `
    }
  }};

  .arrow {
    display: block;

    position: absolute;
    bottom: 30px;
    left: 50%;

    transform: translateX(-50%);
  }
`

export const MobileOverlay = () => {
  const [isHidden, setIsHidden] = useState(false)
  const { y: windowYScroll } = useWindowScroll()

  const ref = useRef(null)
  useClickAway(ref, () => setIsHidden(true))

  useEffect(() => {
    windowYScroll > 0 && setIsHidden(true)
  }, [windowYScroll])

  return (
    <Transition in={!isHidden} timeout={ANIMATION_TIMEOUT} unmountOnExit>
      {state => (
        <Container ref={ref} state={state} onClick={() => setIsHidden(true)}>
          <AnchorLink className="arrow" href="#how-does-it-work">
            <Arrow />
          </AnchorLink>
        </Container>
      )}
    </Transition>
  )
}
