import React from 'react'
import styled from 'styled-components'
import { MessageDescriptor } from 'react-intl'
import { useFormatMessage } from './i18n/hooks'

const Container = styled.div`
  .custom-textarea {
    width: 100%;
    resize: none;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 5px 10px;
    box-sizing: border-box;
    font-family: inherit;

    &:focus {
      outline: none;
    }
  }
`

interface Props {
  name: string
  value: string
  onChange: (newValue: string) => void
  isSubmitting?: boolean
  placeholderDescriotion?: MessageDescriptor
  rows?: number
  required?: boolean
}

const CustomTextarea = (props: Props) => {
  const { name, value, onChange, isSubmitting, placeholderDescriotion, rows, required } = props

  const t = useFormatMessage()

  return (
    <Container>
      <textarea
        className="custom-textarea"
        onChange={e => onChange(e.target.value)}
        placeholder={placeholderDescriotion && t(placeholderDescriotion)}
        disabled={isSubmitting}
        {...{ name, value, rows, required }}
      />
    </Container>
  )
}

export default CustomTextarea
