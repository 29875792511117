import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import Logo from '../../../assets/datari.svg'
import { colors, fontStylesMap } from '../../styles/variables'
import AnchorLink from '../smooth-scroll-link'
import LanguageSwitch from '../i18n/language-switch'
import { NavigationMobileBody } from './navigation-mobile-body'
import { HEADER_HEIGHT, NavigationProps } from './utils'

interface StyleProps {
  isScrolled: boolean
  isOpen: boolean
}

const Container = styled.nav<StyleProps>`
  position: relative;

  z-index: 500;

  & > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-left: 20px;
    padding-right: 10px;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: ${HEADER_HEIGHT}px;

    ${({ isScrolled, isOpen }) =>
      isScrolled || isOpen
        ? css`
            background-color: ${colors.white};
          `
        : css`
            background-color: transparent;
          `};

    & > .header-right {
      display: flex;
      justify-content: space-between;

      ${fontStylesMap.links};

      & > .burger {
        width: 38px;

        display: flex;
        justify-content: center;
        align-items: center;

        font-size: 24px;

        cursor: pointer;
      }
    }
  }
`

const NavigationMobile = (props: NavigationProps) => {
  const { isScrolled, links, buttonLink, logoLink } = props
  const [isOpen, setIsOpen] = useState(false)

  const closeNav = () => setIsOpen(false)

  return (
    <Container isScrolled={isScrolled} isOpen={isOpen}>
      <header className="header">
        <AnchorLink href={logoLink.href} onClick={closeNav}>
          <Logo height={15} width={null} />
        </AnchorLink>
        <div className="header-right">
          {!isOpen && (
            <div>
              <LanguageSwitch />
            </div>
          )}
          <div className="burger" onClick={() => setIsOpen(!isOpen)}>
            <FontAwesomeIcon icon={isOpen ? 'times' : 'bars'} color={colors.gray} />
          </div>
        </div>
        <NavigationMobileBody
          links={links}
          buttonLink={buttonLink}
          isOpen={isOpen}
          onLinkClick={() => setIsOpen(!isOpen)}
        />
      </header>
    </Container>
  )
}

export default NavigationMobile
