import React from 'react'
import styled from 'styled-components'
import { colors } from '../styles/variables'

const Container = styled.div`
  align-self: stretch;
  margin-left: 10px;
  margin-right: 10px;

  border: none;
  border-right: 1px dotted ${colors.borders};

  opacity: 0.3;
`

interface Props {
  className?: string | undefined
}

const Divider = (props: Props) => {
  return <Container {...props} />
}

export default Divider
