import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const query = graphql`
  {
    landing: file(relativePath: { eq: "illustrations/landing-page.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

const LandingPageImage = () => {
  const data = useStaticQuery(query)
  return <Img fluid={data.landing.childImageSharp.fluid} />
}

export default LandingPageImage
