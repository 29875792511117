import React from 'react'
import styled, { css } from 'styled-components'
import { colors, fontStylesMap } from '../../styles/variables'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import ButtonContent from './button-content'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface StyledProps {
  small?: boolean | undefined
}

const Container = styled.button<StyledProps>`
  height: 48px;
  ${({ small }) =>
    small &&
    css`
      height: 42px;
    `};

  padding-left: 22px;
  padding-right: 22px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: ${colors.main};
  border: none;
  border-radius: 24px;
  outline: none;

  font-family: inherit;

  cursor: pointer;
  user-select: none;

  ${fontStylesMap.buttonTextBig};

  ${({ small }) =>
    small &&
    css`
      ${fontStylesMap.buttonText};
    `};

  & .icon {
    margin-right: 10px;
  }
`

export type ClickEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>

export interface ButtonProps extends StyledProps {
  title: string
  type?: 'button' | 'submit' | 'reset' | undefined
  className?: string
  onClick?: (e: ClickEvent) => void
  icon?: undefined | IconProp
  isLoading?: boolean | undefined
  isDisabled?: boolean | undefined
}

const Button = (props: ButtonProps) => {
  const { title, icon, onClick, isDisabled, type, small, className, isLoading } = props

  return (
    <Container
      type={type}
      small={small}
      disabled={isDisabled}
      className={className}
      onClick={e => {
        if (isDisabled || !onClick) {
          return
        }

        onClick(e)
      }}
    >
      {isLoading ? (
        <FontAwesomeIcon icon="circle-notch" className="fa-spin" />
      ) : (
        <ButtonContent icon={icon} title={title} />
      )}
    </Container>
  )
}

export default Button
