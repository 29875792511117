import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import cx from 'classnames'
import { colors } from '../../styles/variables'
import useWindowSize from '../../hooks/use-window-size'

const Container = styled.div`
  display: flex;
  align-items: center;

  & > .control {
    width: 15px;
    height: 15px;

    margin-left: 10px;
    margin-right: 10px;

    border-radius: 50%;

    background-color: ${colors.lightGray};

    cursor: pointer;

    &.active {
      background-color: ${colors.dark};
    }
  }
`

interface Props {
  numberOfChildren: number
  sliderScrollPosition: number
  childrenWidth: number
  onSelect: (index: number) => void
}

export const SliderControls = (props: Props) => {
  const { numberOfChildren, onSelect, childrenWidth, sliderScrollPosition } = props

  const { width: windowWidth } = useWindowSize()
  const [childrenPerControl, setChildrenPerControl] = useState(1)
  useEffect(() => setChildrenPerControl(Math.floor(windowWidth / childrenWidth)), [childrenWidth, windowWidth])

  const getControlsArray = () => {
    const count = childrenPerControl !== 0 ? Math.ceil(numberOfChildren / childrenPerControl) : 1
    return Array(count).fill(undefined)
  }

  const isActive = (index: number): boolean => {
    const leftGuard = index * childrenWidth * childrenPerControl
    const rightGuard = (index + 1) * childrenWidth * childrenPerControl
    return leftGuard <= sliderScrollPosition && sliderScrollPosition < rightGuard
  }

  return (
    <Container>
      {getControlsArray().map((_, i) => (
        <button
          key={i}
          className={cx('control', { active: isActive(i) })}
          onClick={() => onSelect(i * childrenPerControl)}
        />
      ))}
    </Container>
  )
}
