import React from 'react'
import styled from 'styled-components'
import CheckmarkImage from '../../../../assets/check.svg'
import { device, fontStylesMap } from '../../../styles/variables'
import PlusLink from '../../plus-link'
import ShowcaseImage, { ShowcaseImageName } from './showcase-image'

const Container = styled.div<{ flexReverse: boolean }>`
  padding-top: 100px;
  padding-bottom: 100px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${({ flexReverse }) => (flexReverse ? 'row-reverse' : 'row')};

  & > * {
    margin-left: 60px;
    margin-right: 60px;
    margin-bottom: 60px;
  }

  & > .text-wrapper {
    max-width: 500px;

    & .heading {
      ${fontStylesMap.headingTwo}
    }

    & .text {
      margin-top: 30px;

      display: flex;
      justify-content: flex-start;

      ${fontStylesMap.textGray};

      & .checkmark {
        margin-top: 8px;
        width: 25px;
      }

      & p {
        margin-left: 20px;
        width: 450px;
      }
    }

    @media ${device.laptop} {
      & .heading {
        font-size: 30px;
      }

      & .text {
        margin-top: 20px;

        font-size: 18px;
      }
    }

    @media ${device.mobileL} {
      & .text {
        & p {
          width: 300px;
        }
      }
    }

    @media ${device.mobileM} {
      & .text {
        & p {
          width: 200px;
        }
      }
    }
  }

  & .dashboard-image {
    width: 700px;

    @media ${device.laptop} {
      width: 400px;
    }

    @media ${device.mobileM} {
      width: 300px;
    }
  }

  @media ${device.tablet} {
    padding-top: 0px;

    flex-direction: column;
  }
`

interface Props {
  title: string
  texts: string[]
  link?: string | undefined
  index: number
  image: ShowcaseImageName
}

const Showcase = (props: Props) => {
  const { texts, title, link, index, image } = props

  return (
    <Container flexReverse={index % 2 !== 0}>
      <div>
        <ShowcaseImage imageName={image} className="dashboard-image" />
      </div>
      <div className="text-wrapper">
        <h3 className="heading">{title}</h3>
        {texts.map((text, i) => (
          <div key={i} className="text">
            <div className="checkmark">
              <CheckmarkImage />
            </div>
            <p>{text}</p>
          </div>
        ))}
        {link ? <PlusLink title={link} /> : null}
      </div>
    </Container>
  )
}

export default Showcase
