import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

export enum ShowcaseImageName {
  Showcase01 = 'showcase01',
  Showcase02 = 'showcase02',
  Showcase03 = 'showcase03',
}

const query = graphql`
  {
    showcase01: file(relativePath: { eq: "illustrations/showcase01.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    showcase02: file(relativePath: { eq: "illustrations/showcase02.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    showcase03: file(relativePath: { eq: "illustrations/showcase03.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
const Container = styled(Img)``

interface Props {
  className?: string | undefined
  imageName: ShowcaseImageName
}

const ShowcaseImage = (props: Props) => {
  const { className, imageName } = props
  const data = useStaticQuery(query)

  return <Container fluid={data[imageName].childImageSharp.fluid} className={className} />
}

export default ShowcaseImage
