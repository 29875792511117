import React from 'react'
import styled from 'styled-components'
import { colors, fontStylesMap } from '../../../styles/variables'
import Lightbulb from '../../../../assets/lightbulb.svg'
import Fingerprint from '../../../../assets/fingerprint.svg'
import Shield from '../../../../assets/shield.svg'
import PlusLink from '../../plus-link'

const Container = styled.div`
  margin-left: 26px;
  margin-right: 26px;
  margin-bottom: 60px;

  & > .body {
    position: relative;

    width: 312px;
    height: 337px;
    padding: 42px;

    border-radius: 6px;
    background-color: ${colors.white};

    & > .tag {
      position: absolute;
      top: 42px;
      right: 0;

      padding-left: 8px;
      padding-right: 8px;
      padding-top: 4px;
      padding-bottom: 4px;

      border: 1px solid ${colors.main};
      border-right: unset;

      ${fontStylesMap.textLinkBoldColored}
    }

    & > .title {
      margin-top: 10px;

      ${fontStylesMap.cardHeading}

      & .highlighted {
        ${fontStylesMap.cardHeadingColor}
      }
    }

    & > .text {
      margin-top: 10px;

      ${fontStylesMap.cardText}
    }

    & > .icon-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      height: 50px;
    }
  }

  @media (max-width: 600px) {
    & > .body {
      width: 100%;
    }
  }
`

export enum CardIconName {
  Fingerprint = 'fingerprint',
  Shield = 'shield',
  Lightbulb = 'lightbulb',
}

interface Props {
  iconName: CardIconName
  title: string
  highlightedTitle: string
  text: string
  linkText: string
  linkUrl?: string | undefined
  tag?: string | undefined
}

const getIcon = (iconName: CardIconName) => {
  switch (iconName) {
    case CardIconName.Fingerprint:
      return <Fingerprint />
    case CardIconName.Lightbulb:
      return <Lightbulb />
    case CardIconName.Shield:
      return <Shield />
  }
}

const Card = (props: Props) => {
  const { text, title, highlightedTitle, iconName, linkText, linkUrl, tag } = props

  return (
    <Container>
      <div className="body">
        {tag ? <div className="tag">{tag}</div> : null}
        <div className="icon-wrapper">{getIcon(iconName)}</div>
        <div className="title">
          <span>{title}</span>
          <span className="highlighted"> {highlightedTitle}</span>
        </div>
        <div className="text">{text}</div>
      </div>
      {linkText && (
        <div className="link-wrapper">
          <PlusLink title={linkText} url={linkUrl} />
        </div>
      )}
    </Container>
  )
}

export default Card
