import React from 'react'
import styled from 'styled-components'
import { fontStylesMap, device } from '../../../styles/variables'
import DataIcon, { DataIconName } from './data-icon'

const Container = styled.div`
  width: 180px;
  padding-top: 30px;
  padding-bottom: 30px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;

  & > .block-header {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    height: 40px;

    padding-left: 5px;
    padding-right: 5px;

    ${fontStylesMap.textLinkBoldDark};
    text-align: center;
    vertical-align: top;
    line-height: 1.4;
  }

  & > .icon-wrapper {
    margin-top: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media ${device.tablet} {
    margin-bottom: 30px;
  }
`

interface Props {
  text: string
  icon: DataIconName
  className?: string | undefined
}

const DataBlock = (props: Props) => {
  const { text, icon, className } = props

  return (
    <Container className={className}>
      <div className="block-header">{text}</div>
      <div className="icon-wrapper">
        <DataIcon name={icon} />
      </div>
    </Container>
  )
}

export default DataBlock
