import React from 'react'
import styled from 'styled-components'
import Circle from '../../../../assets/circle.svg'
import Dots from '../../../../assets/dots.svg'
import { device } from '../../../styles/variables'
import { useFormatMessage } from '../../i18n/hooks'
import { getMessageArray, getMessageOrUndef } from '../../i18n/utils'
import Showcase from './showcase'
import { ShowcaseImageName } from './showcase-image'

const sections = [ShowcaseImageName.Showcase01, ShowcaseImageName.Showcase02, ShowcaseImageName.Showcase03]

const Container = styled.div`
  position: relative;

  z-index: 1;
`

const SCircle = styled(Circle)`
  position: absolute;
  top: 0;
  right: 0;

  transform: translateX(-10%) translateY(90%);

  z-index: -1;
`

const SDots = styled(Dots)`
  position: absolute;
  top: 0;
  left: 0;

  transform: translateX(0%) translateY(50%);

  z-index: -1;

  @media ${device.laptop} {
    transform: translateX(-30%) translateY(0%);
  }

  @media ${device.mobileL} {
    transform: translateX(-50%) translateY(-160%);
  }
`

const Showcases = () => {
  const t = useFormatMessage()

  return (
    <Container>
      <SCircle />
      <SDots />
      {sections.map((image, index) => {
        const prefix = `appShowcase.${index}`

        return (
          <Showcase
            key={index}
            index={index}
            image={image}
            title={t({ id: `${prefix}.title` })}
            texts={getMessageArray(t, `${prefix}.texts`)}
            link={getMessageOrUndef(t, `${prefix}.link`)}
          />
        )
      })}
    </Container>
  )
}

export default Showcases
