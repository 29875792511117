import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled, { css } from 'styled-components'
import Logo from '../../../assets/datari.svg'
import { fontStylesMap } from '../../styles/variables'
import LanguageSwitch from '../i18n/language-switch'
import { NavigationProps } from './utils'
import { getMarketType } from '../../utils'
import SmoothScrollLink from '../smooth-scroll-link'
import MarketLink from '../links/market-link'

interface StyleProps {
  isScrolled: boolean
}

const Container = styled.nav<StyleProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-top: ${({ isScrolled }) => (isScrolled ? '10px' : '32px')};
  padding-bottom: ${({ isScrolled }) => (isScrolled ? '10px' : '32px')};
  padding-right: 80px;
  padding-left: 80px;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 48px;

  z-index: 500;
  box-sizing: content-box;

  ${({ isScrolled }) =>
    isScrolled
      ? css`
          background-color: rgba(255, 255, 255, 0.9);
        `
      : css`
          background-color: transparent;
        `};

  transition: all 0.2s ease-in-out;

  & .links {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & > * {
      margin-left: 30px;
      ${fontStylesMap.links}
    }
  }

  & .lang-switch {
    margin-right: 40px;
  }
`

const NavigationDesktop = (props: NavigationProps) => {
  const { isScrolled, links, logoLink } = props
  const marketType = getMarketType()

  return (
    <Container isScrolled={isScrolled}>
      <SmoothScrollLink href={logoLink.href}>
        <Logo />
      </SmoothScrollLink>
      <div className="links">
        {links.map((link, i) => (
          <SmoothScrollLink href={link.href} key={i}>
            <FormattedMessage id={link.translationId} />
          </SmoothScrollLink>
        ))}
        <div className="lang-switch">
          <LanguageSwitch />
        </div>
        {marketType && <MarketLink market={marketType} />}
      </div>
    </Container>
  )
}

export default NavigationDesktop
