import React, { FC, useEffect } from 'react'
import Cards from '../components/homepage/card/cards'
import FrequentlyAskedQuestions from '../components/homepage/faq/frequently-asked-questions'
import Features from '../components/homepage/features/features'
import Footer from '../components/homepage/footer/footer'
import LandingPage from '../components/homepage/landing/landing-page'
import Showcases from '../components/homepage/showcase/showcases'
import WhoAreWe from '../components/homepage/who-are-we'
import withIntl from '../components/i18n/with-intl'
import Layout from '../components/layout/layout'
import Navigation from '../components/navigation/navigation'
import { MobileOverlay } from '../components/mobile-overlay'
import useMediaQuery from '../hooks/use-media-query'
import { device } from '../styles/variables'
import { initialSignIn } from '../components/auth/firebase'

const IndexPage: FC = () => {
  const isTablet = useMediaQuery(device.tablet)

  useEffect(() => {
    initialSignIn()
  }, [])

  return (
    <Layout>
      <Navigation />

      <div id="home">
        <LandingPage />
      </div>

      <div id="how-does-it-work">
        <Cards />
        <Showcases />
        <Features />
      </div>

      <div id="who-are-we">
        <WhoAreWe />
      </div>

      <div id="faq">
        <FrequentlyAskedQuestions />
      </div>

      <div id="contact">
        <Footer />
      </div>

      {isTablet && <MobileOverlay />}
    </Layout>
  )
}

export default withIntl(IndexPage)
