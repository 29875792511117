import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import styled, { css } from 'styled-components'
import Dots from '../../../../assets/dots-white.svg'
import { colors, device, fontStylesMap } from '../../../styles/variables'
import Companies from './companies'
import Data from './data'

const responsiveStyles = css`
  @media ${device.tablet} {
    font-size: 30px;
  }

  @media ${device.mobileM} {
    font-size: 20px;
  }
`

const Container = styled.div`
  padding-top: 160px;
  padding-bottom: 100px;

  @media ${device.tablet} {
    padding-bottom: 30px;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  z-index: 1;

  & > .wrapper {
    width: 100%;

    & .header {
      display: flex;
      justify-content: center;
      align-items: center;

      & > h3 {
        position: relative;

        margin-left: 20px;
        margin-right: 20px;

        ${fontStylesMap.headingTwo};
        ${responsiveStyles};

        text-align: center;
        cursor: pointer;

        &.active {
          ${fontStylesMap.headingTwoBold};
          ${responsiveStyles};

          &::before {
            content: '';
            position: absolute;
            bottom: -10px;
            left: 0;
            right: 0;

            height: 4px;

            background-color: ${colors.main};
            z-index: 1;
          }

          &::after {
            content: '';
            position: absolute;
            bottom: -18px;
            left: 50%;

            width: 15px;
            height: 15px;

            border-left: 4px solid ${colors.main};
            border-bottom: 4px solid ${colors.main};

            background-color: ${colors.background};

            transform: translateX(-50%) rotate(-45deg);
            z-index: 5;
          }
        }
      }
    }
  }

  @media ${device.tablet} {
    padding-top: 0;
  }
`

const SDots = styled(Dots)`
  position: absolute;
  top: 0;
  left: 0;

  transform: translateX(-10%) translateY(20%);

  z-index: -1;
`

const Features = () => {
  const [isDataTabOpen, setDataTabOpen] = useState(false)

  return (
    <Container>
      <div className="wrapper">
        <header className="header">
          <h3 className={isDataTabOpen === false ? 'active' : undefined} onClick={() => setDataTabOpen(false)}>
            <FormattedMessage id="companies.heading" />
          </h3>
          <h3 className={isDataTabOpen === true ? 'active' : undefined} onClick={() => setDataTabOpen(true)}>
            <FormattedMessage id="data.heading" />
          </h3>
        </header>
        {isDataTabOpen ? <Data /> : <Companies />}
      </div>
      <SDots />
    </Container>
  )
}
export default Features
