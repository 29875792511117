import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled, { keyframes, css } from 'styled-components'
import { colors } from '../styles/variables'

interface StyleProps {
  isHovered?: boolean | undefined
}

// TODO No Prettier formatting https://github.com/prettier/prettier/issues/5936
const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(224, 189, 106, 0.6);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(224, 189, 106, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(224, 189, 106, 0);
  }
`

const Container = styled.div<StyleProps>`
  width: 40px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${colors.mainOpaque};

  border-radius: 50%;

  & .inner {
    width: 25px;
    height: 25px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: ${colors.white};
    background-color: ${colors.main};

    border-radius: 50%;
    font-size: 16px;

    ${({ isHovered }) =>
      isHovered &&
      css`
        animation: ${pulse} 2s infinite;
      `}
  }
`

interface Props extends StyleProps {
  className?: string
}

const PlusIcon = (props: Props) => {
  return (
    <Container {...props}>
      <div className="inner">
        <FontAwesomeIcon icon="plus" />
      </div>
    </Container>
  )
}

export default PlusIcon
