import React from 'react'
import styled from 'styled-components'
import Card, { CardIconName } from './card'
import { useFormatMessage } from '../../i18n/hooks'
import { getMessageOrUndef } from '../../i18n/utils'
import { device } from '../../../styles/variables'

const cardIcons = [CardIconName.Fingerprint, CardIconName.Shield, CardIconName.Lightbulb]

const Container = styled.div`
  position: relative;

  padding-top: 100px;
  padding-bottom: 100px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  z-index: 20;

  @media ${device.tablet} {
    padding-top: 0;
  }
`

const SectionCards = () => {
  const t = useFormatMessage()

  return (
    <Container>
      {cardIcons.map((cardIcon, index) => {
        const prefix = `cards.${index}`

        return (
          <Card
            key={index}
            iconName={cardIcon}
            highlightedTitle={t({ id: `${prefix}.highlightedTitle` })}
            title={t({ id: `${prefix}.title` })}
            text={t({ id: `${prefix}.text` })}
            linkText={t({ id: `${prefix}.link` })}
            linkUrl={getMessageOrUndef(t, `${prefix}.link:url`)}
            tag={getMessageOrUndef(t, `${prefix}.tag`)}
          />
        )
      })}
    </Container>
  )
}

export default SectionCards
