import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import Circle from '../../../../assets/circle.svg'
import { fontStylesMap, device } from '../../../styles/variables'
import { useFormatMessage } from '../../i18n/hooks'
import Arrow from '../../images/arrow'
import useMediaQuery from '../../../hooks/use-media-query'
import TextLink from '../../text-link'
import AnchorLink from '../../smooth-scroll-link'
import LandingPageImage from './landing-page-image'
import MarketLink from '../../links/market-link'
import { MarketType } from '../../../utils'
import { signInAnonymously, signOut } from '../../auth/firebase'

const Container = styled.div`
  min-height: 100vh;
  padding-top: 100px;
  padding-bottom: 100px;
  padding-left: 80px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  position: relative;
  z-index: 1;

  overflow-x: hidden;

  & > .text-wrapper {
    max-width: 680px;
    padding-left: 30px;
    padding-right: 30px;

    & > .heading {
      ${fontStylesMap.headingOne};

      & .highlighted {
        ${fontStylesMap.headingOneColor};
        font-size: unset;
      }

      @media ${device.tablet} {
        font-size: 34px;
        line-height: 1.6;
      }
    }

    & > .text {
      ${fontStylesMap.textDark}
      margin-top: 30px;

      @media ${device.tablet} {
        font-size: 18px;
      }
    }

    & > .controls {
      position: relative;
      width: 100%
      display: flex;
      align-items: flex-start;
      flex-flow: column;
      flex-wrap: wrap;

      margin-top: 20px;

      .how-does-it-work {
        margin-bottom: 50px;
      }

      & > .text {
        ${fontStylesMap.textDark}
  
        @media ${device.tablet} {
          font-size: 18px;
        }
      }
      
      .markets {
        display: flex;
      }

      & > * {
        margin-top: 20px;
      }

      @media ${device.tablet} {
        align-items: center;
      }

      @media ${device.mobileM} {
        .markets {
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }

  & > .image-wrapper {
    width: 800px;

    margin-right: -80px;

    @media ${device.tablet} {
      margin-right: 0;
    }
  }

  & > .arrow {
    display: block;

    position: absolute;
    bottom: 24px;
    left: 50%;

    transform: translateX(-50%);
  }

  @media ${device.tablet} {
    flex-direction: column;
    align-items: center;

    padding-left: 0;
    padding-top: 200px;
    padding-bottom: 100px;

    & > .image-wrapper {
      align-self: flex-end;
      width: 100%;

      margin-top: 100px;
    }
  }

  @media ${device.tablet} {
    padding-top: 100px;
  }
`

const SCircle = styled(Circle)`
  position: absolute;
  top: 0;
  left: 0;

  transform: translateX(-30%) translateY(-30%);

  z-index: -1;
`

const LandingPage = () => {
  const t = useFormatMessage()
  const isTablet = useMediaQuery(device.tablet)

  return (
    <Container>
      <div className="text-wrapper">
        <h1 className="heading">
          <FormattedMessage id="landingPage.heading" />
          <span className="highlighted">
            {' '}
            <FormattedMessage id="landingPage.headingBold" />
          </span>
        </h1>

        <p className="text">
          <FormattedMessage id="landingPage.subheading" />
        </p>

        <div className="controls">
          <AnchorLink href="#how-does-it-work">
            <TextLink
              className="how-does-it-work"
              title={t({ id: 'common.howDoesItWork' })}
              icon={{ iconName: 'arrow-right', prefix: 'fas' }}
            />
          </AnchorLink>
          <p className="text">
            <FormattedMessage id="landingPage.downloadBeta" />
          </p>
          <div className="markets">
            <MarketLink market={MarketType.appStore} />
            <MarketLink market={MarketType.googlePlay} />
          </div>
        </div>
      </div>

      <div className="image-wrapper">
        <LandingPageImage />
      </div>

      {!isTablet && (
        <AnchorLink href="#how-does-it-work" className="arrow">
          <Arrow />
        </AnchorLink>
      )}

      <SCircle />
    </Container>
  )
}

export default LandingPage
